import Structure from '../modules/structure';
import Swiper from 'swiper/bundle';

$.fn.simpleProjectSliderScript = function() {

    const jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('projectSliderScript', container, {
            "nextSlide": ".projects-slider .next",
            "prevSlide": ".projects-slider .prev",
            "nav": ".projects-slider .nav",
            "slider": ".projects-slider .projects-slider-intermediate",
        }).structure;

        const swiperObj = new Swiper(structure.slider.get(0), {
            slidesPerView: 2,
            spaceBetween: 24, 
            navigation: {
                nextEl: structure.nextSlide.get(0),
                prevEl: structure.prevSlide.get(0),
            },
            pagination: {
                el: structure.nav.get(0),
                clickable: true
            },
            breakpoints: {
                1224: {
                    spaceBetween: 24, 
                },
                767: {
                    spaceBetween: 20, 
                },
                320: {
                    spaceBetween: 0,
                    slidesPerView: 1
                }
            }
        });

    });

    return this;
}

document.addEventListener('DOMContentLoaded', function() {
    $('.simple-project-slider-js').simpleProjectSliderScript();
});