import Structure from '../modules/structure';
import Swiper from 'swiper/bundle';

$.fn.imagesSliderScript = function() {

    const jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('imagesSliderScript', container, {
            "nextSlide": ".controlls .controlls-holder .next",
            "prevSlide": ".controlls .controlls-holder .prev",
            "nav": ".controlls .counter .inner-counter",
            "slider": ".images-slider-intermidiate",
        }).structure;

        const swiperObj = new Swiper(structure.slider.get(0), {
            slidesPerView: 1,
            spaceBetween: 0, 
            navigation: {
                nextEl: structure.nextSlide.get(0),
                prevEl: structure.prevSlide.get(0),
            },
            pagination: {
                el: structure.nav.get(0),
                type: "fraction",
            },
            breakpoints: {
                1224: {
                    
                },
                767: {
                    navigation: {
                        enabled: true
                    },
                    pagination: {
                        clickable: false,
                        type: "fraction"
                    }
                },
                320: {
                    navigation: {
                        enabled: false
                    },
                    pagination: {
                        clickable: true,
                        type: "bullets"
                    }
                }
            }
        });

    });

    return this;
}

document.addEventListener('DOMContentLoaded', function() {
    $('.images-slider-js').imagesSliderScript();
});